import React from "react";
import { motion } from "framer-motion";

const Footer = () => {
  return (
    <footer className="py-6 relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center space-y-4"
        >
          <div className="flex justify-center space-x-6">
            {[
              {
                name: "GitHub",
                url: "https://github.com/hozefaa",
              },
              {
                name: "LinkedIn",
                url: "https://www.linkedin.com/in/hozefa-bharmal-742177323/",
              },
            ].map((social) => (
              <motion.a
                key={social.name}
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#B8A8BC] hover:text-[#00F1FF] transition-colors duration-300 text-sm"
                whileHover={{ y: -2 }}
              >
                {social.name}
              </motion.a>
            ))}
          </div>

          <motion.div
            className="text-[#B8A8BC] text-sm"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            viewport={{ once: true }}
          >
            <p>Designed & Built by Hozefa Bharmal</p>
            <motion.div
              className="mt-2 text-xs"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
              viewport={{ once: true }}
            >
              <a
                href="https://github.com/yourusername/portfolio"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-[#00F1FF] transition-colors duration-300"
              >
                <span className="mr-2">★</span>
                Star this on GitHub
              </a>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>

      {/* Decorative elements */}
      <div className="absolute bottom-0 left-0 w-full h-[1px] bg-gradient-to-r from-transparent via-[#00F1FF]/20 to-transparent" />
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-48 h-[1px] bg-[#00F1FF]/20" />
    </footer>
  );
};

export default Footer;
