import React from "react";
import { motion } from "framer-motion";
import projectOne from "../assets/1.png";
import projectTwo from "../assets/2.png";
import projectThree from "../assets/3.png";

const Projects = () => {
  const projects = [
    {
      title: "Portfolio Website",
      description:
        "A modern portfolio website built with React and Tailwind CSS. Features smooth animations, responsive design, and a space theme.",
      tech: ["React", "Tailwind CSS", "Framer Motion"],
      image: projectOne,
      links: {
        github: "https://github.com/hozefaa",
        live: "https://hozefaa.github.io/portfolio/",
      },
    },
    {
      title: "Gym Website",
      description:
        "A website for a gym built with React and Tailwind CSS. Features a modern design, responsive layout, and a focus on user experience.",
      tech: ["React", "Tailwind CSS", "Framer Motion"],
      image: projectTwo,
      links: {
        github: "https://github.com/hozefaa",
        live: "https://project-two.com",
      },
    },
    {
      title: "Business Website",
      description:
        "A website for a Gujarat Steel Corporation built with Next.js and Tailwind CSS. Features a modern design, responsive layout, and a focus on user experience.",
      tech: ["React", "Tailwind CSS", "Framer Motion"],
      image: projectThree,
      links: {
        github: "https://github.com/hozefaa",
        live: "https://project-three.com",
      },
    },
  ];

  return (
    <section
      id="projects"
      className="py-20 relative"
      aria-labelledby="projects-heading"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="space-y-16"
        >
          <h2 id="projects-heading" className="text-3xl font-bold text-white">
            <span className="text-[#00F1FF]">03. </span>Some Things I've Built
          </h2>

          <div className="space-y-24">
            {projects.map((project, index) => (
              <motion.article
                key={project.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                viewport={{ once: true }}
                className={`relative flex flex-col ${
                  index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                } items-center gap-8`}
              >
                {/* Project Image */}
                <div className="w-full md:w-7/12 relative group">
                  <div className="relative aspect-video rounded-lg overflow-hidden bg-[#BD00FF]/10 backdrop-blur-sm">
                    <div
                      className="absolute inset-0 bg-[#00F1FF]/10 group-hover:bg-transparent transition-colors duration-300"
                      aria-hidden="true"
                    />
                    {project.image ? (
                      <img
                        src={project.image}
                        alt={`Screenshot of ${project.title} project`}
                        className="w-full h-full object-cover object-center group-hover:scale-105 transition-transform duration-300"
                        loading="lazy"
                        width="800"
                        height="450"
                      />
                    ) : (
                      <div className="absolute inset-0 flex items-center justify-center text-[#B8A8BC]">
                        [Project Screenshot]
                      </div>
                    )}
                  </div>
                </div>

                {/* Project Info */}
                <div
                  className={`w-full md:w-5/12 ${
                    index % 2 === 0 ? "md:pl-8" : "md:pr-8"
                  }`}
                >
                  <div className="space-y-4">
                    <h3 className="text-2xl font-bold text-white">
                      {project.title}
                    </h3>

                    <div
                      className="bg-[#0A0118]/50 backdrop-blur-sm p-6 rounded-lg"
                      role="contentinfo"
                    >
                      <p className="text-[#B8A8BC]">{project.description}</p>
                    </div>

                    <ul
                      className="flex flex-wrap gap-3"
                      aria-label={`Technologies used in ${project.title}`}
                    >
                      {project.tech.map((tech) => (
                        <li
                          key={tech}
                          className="text-[#00F1FF] text-sm font-mono"
                        >
                          {tech}
                        </li>
                      ))}
                    </ul>

                    <div
                      className="flex gap-4"
                      aria-label={`Links for ${project.title}`}
                    >
                      <motion.a
                        href={project.links.github}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#B8A8BC] hover:text-[#00F1FF]"
                        whileHover={{ y: -2 }}
                        aria-label={`View ${project.title} source code on GitHub`}
                      >
                        <svg
                          className="w-6 h-6"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </motion.a>
                      <motion.a
                        href={project.links.live}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#B8A8BC] hover:text-[#00F1FF]"
                        whileHover={{ y: -2 }}
                        aria-label={`Visit ${project.title} live site`}
                      >
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                          />
                        </svg>
                      </motion.a>
                    </div>
                  </div>
                </div>
              </motion.article>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Projects;
