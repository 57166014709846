import React, { useState } from "react";
import { motion } from "framer-motion";

const Experience = () => {
  const [activeTab, setActiveTab] = useState(0);

  const skills = [
    {
      category: "Frontend",
      technologies: [
        "HTML & CSS",
        "JavaScript (ES6+)",
        "React.js",
        "Tailwind CSS",
        "Responsive Design",
      ],
    },
    {
      category: "Backend",
      technologies: ["Node.js", "Express.js"],
    },
    {
      category: "Tools",
      technologies: [
        "Git & GitHub",
        "VS Code",
        "Command Line",
        "npm/yarn",
        "Chrome DevTools",
      ],
    },
  ];

  return (
    <section
      id="experience"
      className="py-20 relative"
      aria-labelledby="skills-heading"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="space-y-8"
        >
          <h2 id="skills-heading" className="text-3xl font-bold text-white">
            <span className="text-[#00F1FF]">02. </span>Skills & Technologies
          </h2>

          <div className="flex flex-col md:flex-row gap-8">
            {/* Tabs */}
            <div
              className="flex overflow-x-auto md:flex-col space-x-4 md:space-x-0 md:space-y-2 
                            border-b md:border-b-0 md:border-l border-[#B8A8BC]/20 
                            scrollbar-hide pb-4 md:pb-0"
              role="tablist"
              aria-label="Skills categories"
            >
              {skills.map((skill, index) => (
                <button
                  key={skill.category}
                  className={`px-4 py-2 text-sm font-medium transition-all duration-300 relative ${
                    activeTab === index
                      ? "text-[#00F1FF]"
                      : "text-[#B8A8BC] hover:text-[#00F1FF]"
                  }`}
                  onClick={() => setActiveTab(index)}
                  role="tab"
                  aria-selected={activeTab === index}
                  aria-controls={`tabpanel-${skill.category.toLowerCase()}`}
                  id={`tab-${skill.category.toLowerCase()}`}
                >
                  {skill.category}
                  {activeTab === index && (
                    <motion.div
                      layoutId="activeTab"
                      className="absolute left-0 top-0 w-0.5 h-full bg-[#00F1FF] md:w-full md:h-0.5"
                      aria-hidden="true"
                    />
                  )}
                </button>
              ))}
            </div>

            {/* Content */}
            <div className="flex-1 px-4 sm:px-0">
              {skills.map((skill, index) => (
                <motion.div
                  key={skill.category}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{
                    opacity: activeTab === index ? 1 : 0,
                    x: activeTab === index ? 0 : 20,
                  }}
                  transition={{ duration: 0.3 }}
                  className="space-y-4"
                  role="tabpanel"
                  id={`tabpanel-${skill.category.toLowerCase()}`}
                  aria-labelledby={`tab-${skill.category.toLowerCase()}`}
                  hidden={activeTab !== index}
                >
                  <ul
                    className="grid grid-cols-2 gap-2"
                    aria-label={`${skill.category} technologies`}
                  >
                    {skill.technologies.map((tech, techIndex) => (
                      <motion.li
                        key={tech}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: techIndex * 0.1 }}
                        className="flex items-center space-x-2 text-[#B8A8BC]"
                      >
                        <span className="text-[#00F1FF]" aria-hidden="true">
                          ▹
                        </span>
                        <span>{tech}</span>
                      </motion.li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Experience;
