import React from "react";
import { motion } from "framer-motion";

const Hero = () => {
  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section
      className="min-h-screen flex items-center relative px-4 sm:px-6 lg:px-8"
      aria-label="Introduction"
    >
      <div className="max-w-7xl mx-auto w-full">
        <div className="space-y-5 sm:space-y-8">
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-[#00F1FF] font-mono text-sm sm:text-base"
          >
            Hi there, I'm
          </motion.span>

          <motion.h1
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-4xl sm:text-5xl md:text-7xl font-bold text-white leading-tight"
          >
            Hozefa Bharmal
          </motion.h1>

          <motion.h2
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="text-2xl sm:text-3xl md:text-4xl font-semibold text-[#B8A8BC]"
          >
            Full Stack Developer
          </motion.h2>

          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="max-w-lg text-sm sm:text-base text-[#B8A8BC]/80"
          >
            I'm a first-year Computer Science student at DY Patil University,
            Pune, passionate about creating exceptional digital experiences.
          </motion.p>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.8 }}
            className="flex flex-col sm:flex-row gap-4"
          >
            <motion.button
              onClick={() => scrollToSection("projects")}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-3 bg-[#BD00FF] text-white rounded-lg font-medium 
                       hover:bg-[#00F1FF] transition-colors duration-300
                       focus:outline-none focus:ring-2 focus:ring-[#00F1FF] focus:ring-offset-2"
              aria-label="View my work"
            >
              View My Work
            </motion.button>

            <motion.button
              onClick={() => scrollToSection("contact")}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-3 border-2 border-[#BD00FF] text-[#BD00FF] rounded-lg font-medium 
                       hover:bg-[#BD00FF]/10 transition-colors duration-300
                       focus:outline-none focus:ring-2 focus:ring-[#BD00FF] focus:ring-offset-2"
              aria-label="Contact me"
            >
              Contact Me
            </motion.button>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
