import React from "react";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <section
      id="contact"
      className="py-12 sm:py-20 relative px-4 sm:px-6 lg:px-8"
      aria-labelledby="contact-heading"
    >
      <div className="max-w-7xl mx-auto">
        <motion.div
          className="max-w-2xl mx-auto text-center space-y-4 sm:space-y-8"
          role="region"
          aria-label="Contact information"
        >
          <h2 id="contact-heading" className="text-3xl font-bold text-white">
            <span className="text-[#00F1FF]">04. </span>What's Next?
          </h2>

          <h3
            className="text-4xl sm:text-5xl font-bold text-white"
            id="contact-subheading"
          >
            Get In Touch
          </h3>

          <p className="text-[#B8A8BC] text-lg" role="contentinfo">
            I'm currently looking for new opportunities. Whether you have a
            question or just want to say hi, I'll try my best to get back to
            you!
          </p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            <motion.a
              href="mailto:hozefabharmal00@gmail.com"
              className="inline-block px-8 py-4 border-2 border-[#00F1FF] text-[#00F1FF] rounded-lg font-medium 
                       hover:bg-[#00F1FF]/10 transition-colors duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              aria-label="Send email to Hozefa Bharmal"
              rel="noopener noreferrer"
              itemProp="email"
            >
              Say Hello
            </motion.a>
          </motion.div>
        </motion.div>
      </div>

      {/* Schema.org markup for contact information */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Person",
          name: "Hozefa Bharmal",
          email: "hozefabharmal00@gmail.com",
          description: "Full Stack Developer looking for new opportunities",
          url: "https://your-website.com/#contact",
        })}
      </script>
    </section>
  );
};

export default Contact;
