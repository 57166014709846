import React, { useMemo } from "react";
import { motion } from "framer-motion";

const Background = () => {
  // Use useMemo to prevent regenerating stars on every render
  const staticStars = useMemo(() => {
    return [...Array(100)].map((_, i) => ({
      id: i,
      size: Math.random() * 2 + 1,
      left: Math.random() * 100,
      top: Math.random() * 100,
      opacity: Math.random() * 0.5 + 0.5,
    }));
  }, []);

  const fallingStars = useMemo(() => {
    return Array.from({ length: 20 }).map((_, i) => ({
      id: i,
      size: Math.max(0.5, Math.random() * 2),
      left: `${Math.random() * 100}%`,
      animationDelay: Math.random() * 5,
      duration: 3 + Math.random() * 4,
    }));
  }, []);

  return (
    <div className="fixed inset-0 -z-10 overflow-hidden bg-[#0A0118]">
      {/* Static stars */}
      <div className="absolute inset-0">
        {staticStars.map((star) => (
          <div
            key={star.id}
            className="absolute rounded-full bg-white will-change-transform"
            style={{
              width: `${star.size}px`,
              height: `${star.size}px`,
              transform: `translate(${star.left}vw, ${star.top}vh)`,
              opacity: star.opacity,
            }}
          />
        ))}
      </div>

      {/* Animated falling stars */}
      {fallingStars.map((star) => (
        <motion.div
          key={star.id}
          className="absolute bg-white rounded-full will-change-transform"
          style={{
            width: star.size,
            height: star.size,
            left: star.left,
            top: "-2px",
          }}
          animate={{
            y: ["0vh", "100vh"],
            opacity: [1, 0.5, 0],
          }}
          transition={{
            duration: star.duration,
            repeat: Infinity,
            delay: star.animationDelay,
            ease: "linear",
          }}
        />
      ))}

      {/* Nebula effects */}
      <div className="absolute inset-0 pointer-events-none">
        <motion.div
          className="absolute top-0 left-0 w-[600px] h-[600px] bg-[#BD00FF]/20 rounded-full mix-blend-screen filter blur-[100px] will-change-transform"
          animate={{
            scale: [1, 1.1, 1],
            opacity: [0.3, 0.4, 0.3],
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: "linear",
          }}
        />
        <motion.div
          className="absolute bottom-0 right-0 w-[500px] h-[500px] bg-[#00F1FF]/20 rounded-full mix-blend-screen filter blur-[100px] will-change-transform"
          animate={{
            scale: [1.1, 1, 1.1],
            opacity: [0.3, 0.4, 0.3],
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: "linear",
          }}
        />
      </div>
    </div>
  );
};

// Prevent unnecessary re-renders
export default React.memo(Background);
