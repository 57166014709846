import React from "react";
import { motion } from "framer-motion";

const About = () => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
      className="relative py-20"
      id="about"
      aria-labelledby="about-heading"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="space-y-8"
          >
            <h2 id="about-heading" className="text-3xl font-bold text-white">
              <span className="text-[#00F1FF]">01. </span>About Me
            </h2>

            <div className="space-y-4 text-[#B8A8BC]">
              <p>
                Hello! I'm Hozefa Bharmal, a first-year Computer Science and
                Engineering student at DY Patil University, Pune. My journey in
                coding began in April 2024, and I've been fascinated by the
                world of programming ever since.
              </p>

              <p>
                Currently, I'm focusing on building a strong foundation in web
                development and exploring various programming concepts. While
                I'm new to the field, I'm passionate about creating intuitive
                and dynamic user experiences through code.
              </p>

              <p>
                Here are a few technologies I've been working with recently:
              </p>
            </div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              viewport={{ once: true }}
              className="grid grid-cols-2 gap-2 text-[#B8A8BC]"
            >
              {[
                "JavaScript (ES6+)",
                "React",
                "Node.js",
                "HTML & CSS",
                "Git",
                "Tailwind CSS",
              ].map((skill, index) => (
                <div
                  key={skill}
                  className="flex items-center space-x-2"
                  role="listitem"
                >
                  <span className="text-[#00F1FF]" aria-hidden="true">
                    ▹
                  </span>
                  <span>{skill}</span>
                </div>
              ))}
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default About;
