import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/">
      <motion.div
        className="relative flex items-center group"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        {/* Main Logo Shape */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="relative w-10 h-10 bg-gradient-to-br from-[#BD00FF] to-[#00F1FF] rounded-lg transform -rotate-12"
        >
          {/* Inner Shape */}
          <div className="absolute inset-[2px] bg-[#0A0118] rounded-lg flex items-center justify-center">
            <span className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-[#BD00FF] to-[#00F1FF]">
              H
            </span>
          </div>
        </motion.div>

        {/* Text */}
        <motion.div
          className="ml-3 text-xl font-bold tracking-wider"
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <span className="text-white">DEV</span>
          <span className="text-[#BD00FF]">HB</span>
        </motion.div>

        {/* Hover line */}
        <div className="absolute -bottom-1 left-0 w-full h-[2px] bg-gradient-to-r from-[#BD00FF] to-[#00F1FF] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left" />
      </motion.div>
    </Link>
  );
};

export default Logo;
