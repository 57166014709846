import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header";
import Hero from "./components/Hero";
import About from "./components/About";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Background from "./components/Background";

const App = () => {
  return (
    <Router>
      <div className="relative min-h-screen">
        <Background />
        <div className="relative z-10">
          <Header />
          <main className="overflow-hidden">
            <Hero />
            <About />
            <Experience />
            <Projects />
            <Contact />
          </main>
          <Footer />
        </div>
      </div>
    </Router>
  );
};

export default App;
